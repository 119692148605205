.speaker_img_col {
  margin-right: 55px;
  column-count: 1;
  @media (max-width: 700px) {
    width: 100%;
    align-items: center;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.speaker_desc_col {
  @media (max-width: 700px) {
    width: 100%;
  }
}
