.col_type1 {
  padding-top: 35px;
  /* background: red; */
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  /* transform: translateX(1rem); */
}
body {
  margin: 0;
  padding: 0;
}
